
export const OdooConfig = async () => {


        const config = {
                //pruebas
                host: 'https://pruebasmovilgo.movilgo.com.co',
                port: '',
                database: 'pruebasmovilgo',
                encryptKey: 'zEA2tiTWsWABVz7El8OBxfLN7K5X1Qni',

                //produccion
                 /* host: localStorage.getItem("conexion") === 'pruebas' ? 'http://45.56.65.237' : 'https://movilgoadmin.movilgo.com.co',
                 port: localStorage.getItem("conexion") === 'pruebas' ? '8069' : '',
                 database: localStorage.getItem("conexion") === 'pruebas' ? 'pruebasmovilgo' : 'movilgo',
                 encryptKey: 'zEA2tiTWsWABVz7El8OBxfLN7K5X1Qni' */
                //
        }

        return config;
}
export const version = '1.3'
//pruebas
export const idClienteFinal=376
export const clientSession = { user:'clientefinal', password:'1111' }  
export const Url_Home = 'http://45.79.43.96:8099/LandingPage/' //'https://vendedorpruebas.movilgo.com.co/LandingPage/' 
export const Url_pago = 'http://45.79.43.96:8098/'
export const urlHomeVendedor = 'vendedorpruebas.movilgo.com.co/'
export const idCardoso = '581' //Id padre Cardoso
export const idCardosoVendedor = '647'
export const idVendedorCardoso24 = '779'
export const idCallcenter = '649'
export const idMobilgoVendedor = '648'
export const idvendedorqr = '660' //VendedorRata QR
export const idPadreRata = '658'
export const idLandinPage = ['idOcultarMenu', 'nuevoID']

//produccion

/* export const idClienteFinal=376
export const clientSession = { user:'clientefinal', password:'1111' }  
export const Url_Home='https://vendedor.movilgo.com.co/LandingPage/'
export const Url_pago='https://movilgo.com.co/'
export const urlHomeVendedor = 'vendedor.movilgo.com.co/'
export const idCardoso='581'
export const idCardosoVendedor = '642'
export const idVendedorCardoso24 = '779'
export const idCallcenter = '651' 
export const idMobilgoVendedor = '643'
export const idvendedorqr = '687' //VendedorRata QR
export const idPadreRata = '684'
export const idLandinPage = ['740', '745', '643']  */


